import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Exit } from '@indriver/mireska';
import { Cell } from '@indriver/nova';
import { useBreakpoints } from 'common/lib';
import { removeAccessToken } from 'common/lib/auth';
import { HeaderButton } from 'common/ui';

interface IProps {
    desktopMode?: boolean;
}

export const LogoutButton = ({ desktopMode }: IProps) => {
    const { t } = useTranslation();
    const router = useRouter();
    const media = useBreakpoints();

    const shouldShowLogoutButton = !['/auth', '/auth/create-account'].includes(router.pathname);

    const handleUserLogout = async () => {
        removeAccessToken();
        await router.push('/').then(() => router.reload());
    };

    if (!shouldShowLogoutButton) {
        return null;
    }
    if (!media.isGtS || desktopMode) {
        return (
            <Cell
                size='s'
                prefix={<Exit size={24} color='var(--text-and-icon-secondary)' />}
                onClick={handleUserLogout}>
                {t('header_logout_button_title')}
            </Cell>
        );
    }

    return (
        <HeaderButton prefix={<Exit size={20} />} onClick={handleUserLogout}>
            {t('header_logout_button_title')}
        </HeaderButton>
    );
};
