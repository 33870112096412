export const getPrecalculatedWidths = (element: HTMLElement, moreWidthId: string) => {
    const containerRect = element.getBoundingClientRect();
    const containerWidth = containerRect.width;
    const containerLeft = containerRect.left;
    const children = Array.from(element.children) as HTMLElement[];
    let moreWidth = 40;
    const necessaryWidths = children
        .reduce<number[]>((result, node) => {
            if (node.getAttribute('id') === moreWidthId) {
                moreWidth += node.getBoundingClientRect().width;
                return result;
            }
            const nodeRect = node.getBoundingClientRect();
            const width = containerWidth - (nodeRect.left - containerLeft) + 130;
            return [...result, width];
        }, [])
        .reverse();

    return {
        necessaryWidths,
        containerWidth,
        moreWidth,
    };
};
